.Footer-container{
    width: 100%;
    max-width: 1080px;
}
.Footer-main {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    position: relative;
}
.Footer-sub-link{
    background-color: #009ee2;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Footer-container-link{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    width: 100%;
    max-width: 1080px;
}
.Footer-sub-link a{
    margin: 5px 10px;
    color: #2e3192 !important;
    transition: all ease 0.5s;
}
.Footer-sub-link a:hover{
    margin: 5px 10px;
    color: white !important;
}
.Footer-container-sub{
    padding: 15px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    color: white;
    width: 100%;
    padding: 10px 30px;
    max-width: 1080px;
}
.Footer-sub {
    background: #69a33b;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Footer-main a {
    text-decoration: none;
    color: white;
}
.Footer-main .flex a:hover {
    color: white;
}

.Footer-main .no-padding {
    margin: 0;
}
.FooterIcons{
    margin: 0 5px;
    fill: #2e3192;
}
.FooterIcons:hover{
    animation-name: animFill;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    -webkit-animation-name: animFill;
    -webkit-animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
}

@keyframes animFill {
    0% { fill: #2e3192; }
    100% {  fill: #fff; }
}
@-webkit-keyframes animFill {
    0% {  fill: #2e3192; }
    100% { fill: #fff }
}

@media screen and (max-width: 600px) {
    .Footer-container-link{
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        align-items: center;
        padding: 10px;
        width: 100%;
        max-width: 1080px;
    }
    .Footer-container-link a {
        width: 40%;
        font-size: 0.9rem;
    }
    .Footer-container-sub {
        background: #69a33b;
        width: 100%;
        display: flex;
        gap: 4px;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }
}