.Destino-Paquetes-main {
    width: 100%;
    margin: 0 auto;
    padding: 25px 0;
    position: relative;
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    align-items: center;
}
.Destino-Paquetes-main-content{
    margin: 40px auto;
}