.PaquetesDetellaContentMain{
    position: relative;
}
.Paquetes-Detalle-main-content{
    margin: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Paquetes-Detalle-main {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
}
.PaquetesDetalle-Galeria{
    grid-column: 1 / 4;
    grid-row: 1;
    width: 100%;
    overflow: hidden;
    border-radius: 25px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    max-height: 300px;
}
.PaquetesDetalle-Flotante{
    grid-column: 1 / 2 ;
    grid-row: 2;
    position: relative;
}
.PaquetesDetalle-Content{
    grid-column: 2 / 4;
    grid-row: 2;
}

.PaquetesDetalle-Galeria .portada img{
    width: 100%;
    height: auto;
}
.PaquetesDetalle-Galeria .portada{
    grid-column: 1 / 2;
    grid-row: 1;
}
.PaquetesDetalle-Galeria .galeria{
    grid-column: 2 / 3;
    grid-row: 1;
    width: 100%;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
    grid-auto-rows: minmax(100px, auto);
}
.PaquetesDetalle-Galeria .galeria img{
    width: 100%;
    height: auto;
    transition: all 2s ease-in-out;
}

.PaquetesDetalle-Galeria .galeria .galeria-portada {
    grid-column: 1 / 3;
    grid-row: 1;
    max-height: 170px;
    overflow: hidden;
  
}
.PaquetesDetalle-Galeria .galeria .galeria-uno{
    grid-column: 1 / 2;
    grid-row: 2;
    overflow: hidden;
    max-height: 110px;
}
.PaquetesDetalle-Galeria .galeria .galeria-dos{
    grid-column: 2 / 3;
    grid-row: 2;
    max-height: 110px;
    overflow: hidden;
}

.PaquetesDetalle-Galeria .galeria .galeria-portada.default {
    grid-column: 1 / 3;
    grid-row: 1;
    max-height: 170px;
    overflow: hidden;
  
}
.PaquetesDetalle-Galeria .galeria .galeria-uno.default{
    grid-column: 1 / 2;
    grid-row: 2;
    overflow: hidden;
    max-height: 170px;
}
.PaquetesDetalle-Galeria .galeria .galeria-dos.default{
    grid-column: 2 / 3;
    grid-row: 2;
    max-height: 170px;
    overflow: hidden;
}

.PaquetesDetalle-Galeria .galeria{
    position: relative;
}
.PaquetesDetalle-Galeria .galeria .galery-Boton {
    position: absolute;
    bottom: 0;
    right: 0;
}
.PaquetesDetalle-Galeria .galeria .galery-Boton button {
    font-size: 1rem;
    border: 1px white solid;
    color: white;
    background: #679F3A;
    border-radius: 5px;
    transition: all ease 0.5s;
}
.PaquetesDetalle-Galeria .galeria .galery-Boton button:hover {
    font-size: 1rem;
    border: 1px #679F3A solid;
    color: #679F3A;
    background: white;
    border-radius: 5px;
}

@media screen and (max-width: 1072px) {
    .PaquetesDetalle-Galeria {
        max-height: 265px;
    }
}
@media screen and (max-width: 912px) {
    .PaquetesDetalle-Galeria {
        max-height: 250px;
    }
}
@media screen and (max-width: 830px) {
    .PaquetesDetalle-Galeria {
        max-height: 230px;
    }
}
@media screen and (max-width: 768px) {
    .PaquetesDetalle-Galeria {
        grid-column: 1 / 4;
        grid-row: 1;
        width: 100%;
        overflow: hidden;
        border-radius: 25px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        grid-auto-rows: minmax(100px, auto);
        max-height: 200px;
    }
    
}
@media screen and (max-width: 600px) {
    .PaquetesDetalle-Galeria {
        grid-column: 1 / 4;
        grid-row: 1;
        width: 100%;
        overflow: hidden;
        border-radius: 25px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        grid-auto-rows: minmax(100px, auto);
    }
    .Paquetes-Detalle-main {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
        grid-auto-rows: minmax(100px, auto);
    }
    .PaquetesDetalle-Flotante{
        grid-column: 1 / 4 ;
        grid-row: 2;
        position: relative;
    }
    .PaquetesDetalle-Content{
        grid-column: 1 / 4;
        grid-row: 3;
    }
    
}

@media screen and (max-width: 540px) {
    .PaquetesDetalle-Galeria .galeria{
        grid-auto-rows: minmax(70px, auto);
    }
    .PaquetesDetalle-Galeria {
        grid-column: 1 / 4;
        grid-row: 1;
        width: 100%;
        overflow: hidden;
        border-radius: 25px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        grid-auto-rows: minmax(100px, auto);
        max-height: 200px;
    }
    
}

@media screen and (max-width: 450px) {
    .PaquetesDetalle-Galeria .galeria{
        grid-auto-rows: minmax(50px, auto);
    }
    .PaquetesDetalle-Galeria {
        max-height: 110px;
    }
    .PaquetesDetalle-Galeria .galeria .galery-Boton button {
        font-size: 0.7rem;
        border: 1px white solid;
        color: white;
        background: #679F3A;
        border-radius: 5px;
        transition: all ease 0.5s;
    }
}

@media screen and (max-width: 425px) {
    .PaquetesDetalle-Galeria .galeria{
        grid-auto-rows: minmax(50px, auto);
    }
    .PaquetesDetalle-Galeria {
        max-height: 110px;
    }
}