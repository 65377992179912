.Conocenos-container-div {
    margin: 0 auto;
    background: linear-gradient(to right, rgb(0, 0, 0, 0.3), rgb(0, 0, 0, 0.3)), url("./../../assets/wallConocenos.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
    min-height: 80vh;
    padding: 20px 0;
}
.Conocenos-container-preguntas{
    margin: 0 auto;
    background: linear-gradient(to right, rgb(0, 0, 0, 0.3), rgb(0, 0, 0, 0.3)), url("./../../assets/conocenos-dos.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
    min-height: 80vh;
    padding: 20px 0;
}
.Conocenos-container-comentarios{
    margin: 0 auto;
    background: linear-gradient(to right, rgb(0, 0, 0, 0.3), rgb(0, 0, 0, 0.3)), url("./../../assets/conocenos-wall3.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
    min-height: 80vh;
    padding: 20px 0;
}
.Conocenos-container-comentarios .Comentarios-Container{
    margin: 10px auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}
.Conocenos-container-div .Conocenos-container-header {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 40px 120px;
}
.Conocenos-container-div .Conocenos-video iframe{
    width: 100%;
    height: auto;
    min-height: 450px;
    max-width: 800px;
}

.Conocenos-container-dicen{
    padding: 30px 0;
    margin: 0 auto;
    min-height: 80vh;
    background-color: #EAEAEA;
}
.dicen-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    max-width: 1800px;
    margin: 0 auto;

}
.dicen-container .dicen-item {
    width: 600px;
    height: 400px;
    padding: 0 40px;
    margin: 0 10px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
}
.dicen-container .dicen-item img{
    width: 100%;
    height: auto;
    max-width: 250px;
    margin-bottom: 20px;
}
.Conocenos-container-preguntas .preguntas-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
}
.preguntas-container .preguntas .accordion-item button,
.preguntas-container .preguntas .accordion-item{
    background-color: transparent;
    color: #fff;
    max-width: 600px;
}
.preguntas-container .vermas {
    align-self: flex-end;
}
.preguntas-container .vermas a{
    background-color: #0092D2;
    border: none;
    padding: 10px;
    border-radius: 15px;
    color: #fff;
    text-transform: uppercase;
}
.Conocenos-container-pagos{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    padding: 20px 10px;
    background: #EAEAEA;
}
.Conocenos-container-pagos .flujo{
    grid-column: 1 / 1;
    grid-row: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
}
.Conocenos-container-pagos .titulo{
    grid-column: 2 / 4;
    grid-row: 1; 
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.Conocenos-container-pagos .flujo img{
    max-width: 240px;
    margin: 20px 0;

}
.Conocenos-container-pagos .contenido{
    grid-column: 2 / 3;
    grid-row: 2;
    margin-top: 30px;
}
.Conocenos-container-pagos .contenido img{
    max-width: 50px;
}
.Conocenos-container-pagos .contenido .pago-Check{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: #0092D2;
}
.Conocenos-container-pagos .contenido .pasos {
    text-align: left;
    color: #C7C7C7;
    margin-top: 50px;
}
.Conocenos-container-pagos .contenido .pasos span{
    color: #0092D2;
}
.Conocenos-container-pagos .Movil{
    grid-column: 3 / 4;
    grid-row: 2;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: sticky;
    top: 10px;
}
.Conocenos-container-pagos .Movil img{
    max-width: 500px;
    margin: 20px 0;
}
.Conocenos-container-pagos img {
    width: 100%;
    height: auto;

}

@media screen and (max-width: 600px) {
    .Conocenos-container-comentarios{
        margin: 0 auto;
        background: linear-gradient(to right, rgb(0, 0, 0, 0.3), rgb(0, 0, 0, 0.3)), url("./../../assets/conocenos-tres.jpg");
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: 100% 100%;
        min-height: 80vh;
        padding: 20px 0;
    }
    .Conocenos-container-div {
        margin: 0 auto;
        background: linear-gradient(to right, rgb(0, 0, 0, 0.3), rgb(0, 0, 0, 0.3)), url("./../../assets/conocenos-paper.jpg");
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: 100% 100%;
        min-height: 80vh;
    }
    .Conocenos-container-div .Conocenos-container-header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 40px 40px;
    }
    .Conocenos-container-div .Conocenos-video iframe{
        width: 100%;
        height: auto;
        min-height: 250px;
        max-width: 400px;
    }
    .Conocenos-container-preguntas .preguntas-container{
        flex-flow: column nowrap
    }
    .preguntas-container .vermas {
        align-self: center;
        margin: 40px 0;
    }
    .Conocenos-container-pagos .contenido{
        grid-column: 2 / 4;
        grid-row: 2;
        margin-top: 30px;
    }
    .Conocenos-container-pagos .Movil{
        grid-column: 2 / 4;
        grid-row: 3;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .Conocenos-container-pagos .titulo{
        grid-column: 1 / 4;
        grid-row: 1; 
        justify-content: center;
    }
    .Conocenos-container-pagos .contenido img{
        max-width: 30px;
    }
}