.HeaderContainer-white{
    width: 100%;
    padding: 0 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    background: #69a33b;
    position: relative;
    z-index: 98;
}
.HedaerSocial-white img{
    max-width: 35px;
    width: 100%;
    height: auto;
}
.HeaderLogo-white img {
    max-width: 120px;
    width: 100%;
    height: auto;
}
.HeaderContainer-white h5 {
    color: white;
    font-size: 1.5rem;
}

@media  screen and (max-width: 600px) {
    .HeaderContainer-white{
        width: 100%;
        padding: 20px 10px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        background: #69a33b;
        position: relative;
    }
    .HeaderContainer-white h5 {
        color: white;
        font-size: 1rem;
    }
    
}