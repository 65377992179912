.DestinoContainer{
    width: 100%;
}
.DestinoCard{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: all ease 0.5s;
    width: 100%;
    height: 80px;
    cursor: pointer;
    margin: 20px;
    border-radius: 50px;
    overflow: hidden;
    background: #E7E7E7;
}
.DestinoCard:hover{
    box-shadow: rgba(105, 158, 59, 0.25) 0px 54px 55px, rgba(105, 158, 59, 0.12) 0px -12px 30px, rgba(105, 158, 59, 0.12) 0px 4px 6px, rgba(105, 158, 59, 0.17) 0px 12px 13px, rgba(105, 158, 59, 0.09) 0px -3px 5px;
}
.DestinoCard img{
    position: absolute;
    left: 20px;
    width: 100%;
    height: auto;
    max-width: 90px;
}
.DestinoContainer h2 {
    text-align: center;
    color: #19577D;
}

@media screen and (max-width: 600px) {
    .DestinoCard{
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        transition: all ease 0.5s;
        width: 100%;
        height: 50px;
        cursor: pointer;
        margin: 10px 5px;
        border-radius: 50px;
        overflow: hidden;
        background: #E7E7E7;
    }
    .DestinoCard img{
        position: absolute;
        left: 10px;
        width: 100%;
        height: auto;
        max-width: 50px;
    }
}