.main-SubTitulo-alternative{
    text-align: center;
    margin-bottom: 30px;
    margin-top: 30px;
}
.main-SubTitulo-alternative.white h2 {
    color: #fff;
    font-size: 2.1rem;
}
.main-SubTitulo-alternative.white h2::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    right: 0;
    margin: 0 auto;
    width: 55%;
    height: 4px;
    background-color: #fff;
}
.main-SubTitulo-alternative.white h2::after{
    content: "";
    position: absolute;
    left: 0;
    top: -10px;
    right: 0;
    margin: 0 auto;
    width: 55%;
    height: 4px;
    background-color: #fff;
}
.main-SubTitulo-alternative h2 {
    color: #69a33b;
    font-size: 2.1rem;
    text-transform: uppercase;
}
.main-SubTitulo-alternative h2::after{
    content: "";
    position: absolute;
    left: 0;
    top: -10px;
    right: 0;
    margin: 0 auto;
    width: 55%;
    height: 4px;
    background-color: #69a33b;
}
.main-SubTitulo-alternative h2::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    right: 0;
    margin: 0 auto;
    width: 55%;
    height: 4px;
    background-color: #69a33b;
}
@media screen and (max-width: 600px) {
    .main-SubTitulo-alternative h2 {
        color: #69a33b;
        font-size: 1.7rem;
    }
}