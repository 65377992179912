.SalidasTable{
    width: 100%;
}

.SalidasTable table {
    width: 100%;
    padding: 10px;
  }
  .SalidasTable  table td:first-child,
.SalidasTable  table th {
    background-color: #006688;
    border: 1px solid white;
    color: white;
    text-transform: capitalize;
    padding: 10px;
  }
.SalidasTable  table td {
    background-color: #AADCED;
    color: #4D7EA0;
    border: 1px solid white;
    padding: 10px;

  }