.BotonDooddo{
    width: 200px;
    height: auto;
    padding: 10px;
    margin: 5px;
    background: white;
    color: #2e3192;
    border: none;
    border-radius: 5px;
    transition: all ease 0.5s;
    font-weight: 700;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
    text-decoration: none;
}
.BotonDooddo:hover {
    color: white;
    background: #006689;
}