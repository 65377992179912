.PaqueteCard-Container{
    position: relative;
    width: 100%;
    max-width: 450px;
    height: 200px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    background-color: #E5E5E5;
    padding: 0 24px 0 0;
    margin: 12px;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    transition: all ease 0.5s;
}
.PaqueteCard-Container:hover{
    box-shadow: rgba(105, 158, 59, 0.25) 0px 54px 55px, rgba(105, 158, 59, 0.12) 0px -12px 30px, rgba(105, 158, 59, 0.12) 0px 4px 6px, rgba(105, 158, 59, 0.17) 0px 12px 13px, rgba(105, 158, 59, 0.09) 0px -3px 5px;

}
.PaqueteCard-Container-boxImagen {
    overflow: hidden;
    width: 400px;
    max-width: 200px;
}
.PaqueteCard-Container .PaqueteCard-Container-boxImagen img{
    width: 100%;
    height: auto;
    min-width: 200px;
    transition: all ease 0.5s;
    overflow: hidden;
}
.PaqueteCard-main{
    width: 100%;
    margin-left: 10px;
    text-align: left;
}
.PaqueteCard-main P{
    margin: 0;
}
.PaqueteCard-main .font-small{
    font-size: 0.8rem;
    margin: 5px 0;
    color: #646464;
}

.PaqueteCard-verMas{
    position: absolute;
    bottom: 5px;
    right: 25px;
    text-decoration: none;
    color: #2DA6DB;
    margin: 0;
    padding: 0;
    border: none;
    font-family: "Poppins";
}
.PaqueteCard-verMas::before {
    content: "";
    position: absolute;
    right: -25px;
    bottom: 2px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid transparent;
    border-left: 10px solid #2DA6DB;
}
@media screen and (max-width: 600px) {
    .PaqueteCard-Container{
        height: 220px;
    }
    .PaqueteCard-Container-boxImagen {
        overflow: hidden;
        width: 300px;
    }
    .PaqueteCard-Container .PaqueteCard-Container-boxImagen img{
        width: 100%;
        height: auto;
        min-width: 230px;
        transition: all ease 0.5s;
        overflow: hidden;
    }
    .PaqueteCard-main{
        width: 100%;
        margin-left: 10px;
        text-align: left;
    }
    .PaqueteCard-main h5 {
        font-size: 0.9rem;
    }
    .PaqueteCard-main p {
        font-size: 0.9rem;
    }
}