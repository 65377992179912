.MenuIcon-container {
    display: inline-block;
    cursor: pointer;
    position: relative;
    z-index: 999;
  }
  
  .MenuIcon-bar1, .MenuIcon-bar2, .MenuIcon-bar3 {
    width: 35px;
    height: 5px;
    background-color: #B4B3B4;
    margin: 6px 0;
    transition: 0.4s;
  }
  
  .MenuIcon-change .MenuIcon-bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
  }
  
  .MenuIcon-change .MenuIcon-bar2 {opacity: 0;}
  
  .MenuIcon-change .MenuIcon-bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
  }