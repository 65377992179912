.Coment-comentario{
    width: 100%;
    min-height: 150px;
    background-color: rgba(0, 0, 0, 0.7);
    max-width: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;
    border-radius: 100px;
    position: relative;
    padding: 5px 30px;
    margin: 5px 0;
}
.Coment-comentario .coment-start{
    margin: 5px 0;
}
.Coment-comentario .coment-start img{
    width: 100%;
    height: auto;
    max-width: 20px;
}
.Coment-comentario .Coment-photo{
    position: absolute;
    width: 100%;
    height: auto;
    max-width: 150px;
    border-radius: 100px;
}
.Coment-comentario .Coment-photo.left{
    left: 0;
}
.Coment-comentario .Coment-photo.right {
    right: 0;
}
.Coment-comentario .Coment-descripcion{
    color: #fff;
    margin: 0 150px;
}
.Coment-comentario .Coment-descripcion.left{
    text-align: left;
}
.Coment-comentario .Coment-descripcion.right{
    text-align: right;
}
@media screen and (max-width: 600px) {
    .Coment-comentario{
        width: 100%;
        min-height: 150px;
        background-color: rgba(0, 0, 0, 0.7);
        max-width: 700px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: row nowrap;
        border-radius: 100px;
        position: relative;
        padding: 5px 30px;
        margin: 5px 10px;
    }
    .Coment-comentario .Coment-descripcion p{
        font-size: 0.8rem;
    }
    .Coment-comentario .Coment-descripcion{
        color: #fff;
        margin: 0 50px;
    }
    .Coment-comentario .Coment-descripcion.left{
        text-align: left;
        margin-left: 130px;
    }
    .Coment-comentario .Coment-descripcion.right{
        text-align: right;
        margin-right: 130px;
    }
}