.SuplementoTable{
    width: 100%;
}

.SuplementoTable table {
    width: 100%;
    padding: 10px;
  }
.SuplementoTable  table th {
    background-color: #006688;
    border: 1px solid white;
    color: white;
    padding: 10px;
  }
.SuplementoTable  table td {
    background-color: #AADCED;
    color: #4D7EA0;
    border: 1px solid white;
    padding: 10px;
  }