.DestinosMenu{
    position: relative;
}
.DestinoMenu-icons{
    background-color: #D7EEF4;
    position: absolute;
    width: 100%;
    right: 0;
    left: -32px;
    top: -2000px;
    margin: 0 auto;
    min-width: 100px;
    z-index: 999;
    border-radius: 100px;
    padding: 10px;
    transition: all ease 0.2s;
}
.DestinoMenu-icons::before{
    content: "";
    position: absolute;
    background-color: #D7EEF4;
    width: 100%;
    min-width: 100px;
    min-height: 190px;
    border-radius: 100px;
    top: -100px;
    left: 0;
    transition: all ease 0.2s;
}
.DestinoItemMenu-container {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    transition: all ease 1s;

}
.DestinoItemMenu-container img{
    width: 100%;
    height: auto;
    max-width: 50px;
    margin: 5px 0;
    cursor: pointer;
}
.DestinoItemMenu-container:hover {
    transform: scale(1.2);
}
.DestinoMenu-icons.destinoActivo  {
    animation-name: menuDespliegue;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    -webkit-animation-name: menuDespliegue;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
}

.DestinoMenu-icons.destinoDesactive  {
    animation-name: menuNoDespliegue;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    -webkit-animation-name: menuNoDespliegue;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
}
@keyframes menuDespliegue {
    0% { top: -2000px; }
    100% {  top: -10px; }
}
@-webkit-keyframes menuDespliegue {
    0% { top: -2000px; }
    100% {  top: -10px; }
}
@keyframes menuNoDespliegue {
    0% { top: -10px; }
    100% {  top: -2000px; }
}
@-webkit-keyframes menuNoDespliegue {
    0% { top: -10px; }
    100% {  top: -2000px; }
}