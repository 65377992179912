.ModalPrimaryForm{
    position: fixed;
    right: 0;
    bottom: 0;
    margin: 10px 5px;
    z-index: 99;
}
.ModalPrimaryForm-button{
    color: #fff;
    background-color: #6DA145;
    border: 1px solid #65943e;
    padding: 10px;
    border-radius: 15px;
    transition: all ease 0.2s;
    font-size: 1rem;
    font-family: inherit;
    font-weight: 600;
}
.ModalPrimaryForm-button:hover{
    color: #6DA145;
    background-color: #fff;
}

.ModalPrimaryFomr-Modal .modal-content{
    background-color: #6C9E3D;
}
.ModalPrimaryFomr-Modal .modal-header .btn-close {
    background-color: #99DD77;
    border-radius: 50px;
}
.ModalPrimaryFomr-Modal .modal-header {
    color: white;
    text-align: center;
    justify-content: center;
    border-bottom: none;
}
.ModalPrimaryFomr-Modal .modal-body .form-select,
.ModalPrimaryFomr-Modal .modal-body .input-group-text,
.ModalPrimaryFomr-Modal .modal-body .form-control{
    border-radius: 25px !important;
}
.ModalPrimaryFomr-Modal .modal-body .form-select{
    padding-right: 0rem;
    max-width: 150px;
    margin-right: 5px;
}

.ModalPrimaryFomr-Modal .modal-footer{
    border-top: none;
}
.ModalPrimaryFomr-Modal .modalbton {
    color: #fff;
    background-color: #558135;
    border: 2px solid #558135;
    padding: 10px;
    border-radius: 15px;
    transition: all ease 0.2s;
}
.ModalPrimaryFomr-Modal .modalbton:hover {
    color: #558135;
    background-color: #fff;
    border: 2px solid #558135;
    padding: 10px;
    border-radius: 15px;
    transition: all ease 0.2s;
}

.modal-success-main-text{
    text-align: center;
}
.modal-success-main-text ul li,
.modal-success-main-text p{
    color: #fff;
}
.modal-success-main-text h2,
.modal-success-main-text h4{
    color: #415726;
}
.modal-success-main-text ul{
    text-align: left;
    margin-left: 50px;
}
.modal-success-main-text li{ 
    list-style: none;
}
.modal-success-main-text li::before{
    content: "\2022";
    color: #fff;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}