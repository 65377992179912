.preguntasFrecuentes-main{
    margin: 25px auto;
}
.preguntasFrecuentes-main .accordion-body,
.preguntasFrecuentes-main h2 button{
    font-family: "Poppins" !important;
    font-size: 1rem;
    text-align: left;
}
.preguntasFrecuentes-main h1{
    font-family: "Poppins" !important;
    font-size: 1.5rem !important;
}
