.Sectur-main {
    width: 100%;
    margin: 0 auto;
    padding: 25px 0;
    position: relative;
}
.Sectur-main img {
    width: 100%;
    height: auto;
}
