.Paquetes-main {
    width: 100%;
    margin: 0 auto;
    padding: 25px 0;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
}
.Paquetes-main-content{
    margin: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Paquetes-main-content img {
    width: 100%;
    height: auto;
    max-width: 100px;
    margin: 0 15px;
}
.Paquete-main-content-flex{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row nowrap;
}
@media screen and (max-width: 600px) {
    .Paquetes-main-content{
        margin: 0 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .Paquetes-main-content img {
        width: 100%;
        height: auto;
        max-width: 60px;
        margin: 0 10px;
    }
    
}