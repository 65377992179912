.About-main {
    width: 100%;
    margin: 0 auto;
    padding: 25px 0;
    position: relative;
}

.About-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
}

.About-grid img {
    width: 100%;
    height: auto;
}

.About-one {
    grid-column: 1 / 1;
    grid-row: 1;
}

.About-two {
    grid-column: 2 / 2;
    grid-row: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 15px;
    background-color: #fff;
}

.About-three {
    grid-column: 1 / 2;
    grid-row: 2;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-end;
    text-align: right;
    padding: 15px;
    background-color: #fff;
}

.About-four {
    grid-column: 2 / 2;
    grid-row: 2;

}

.About-five {
    grid-column: 1 / 1;
    grid-row: 3;
}

.About-six {
    grid-column: 2 / 2;
    grid-row: 3;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    padding: 15px;
    background-color: #fff;
}

.About-seven {
    grid-column: 1 / 3;
    grid-row: 4;
    background-color: #009ee2;
    padding: 25px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    color: #fff;
}

.About-seven img {
    max-width: 90px;
}

.Avout-seven-item {
    width: 300px;
}

@media screen and (max-width: 600px) {
  
.About-one {
    grid-column: 1 / 3;
    grid-row: 1;
}

.About-two {
    grid-column: 1 / 3;
    grid-row: 2;
    text-align: left;
}

.About-three {
    grid-column: 1 / 3;
    grid-row: 4;
    text-align: left;
    align-items: flex-start;
}

.About-four {
    grid-column: 1 / 3;
    grid-row: 3;
}

.About-five {
    grid-column: 1 / 3;
    grid-row: 5;
}

.About-six {
    grid-column: 1 / 3;
    grid-row: 6;
}

.About-seven {
    grid-column: 1 / 3;
    grid-row: 7;
}

}