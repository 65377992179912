.main-titulo{
    text-align: center;
    margin-bottom: 30px;
}
.main-titulo h1 {
    color: #69a33b;
}
.main-titulo>::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    right: 0;
    margin: 0 auto;
    width: 25%;
    height: 4px;
    background-color: #69a33b;
}

.main-titulo.white h1 {
    color: #ffffff;
}
.main-titulo.white>::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    right: 0;
    margin: 0 auto;
    width: 25%;
    height: 4px;
    background-color: #ffffff;
}