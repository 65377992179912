.TabDetalleContent{
    margin: 0 15px;
}
.TabDetalleItemContent{
    padding: 35px;
    text-align: left;
}
.TabSubTitulo{
    text-align: left;
    margin-top: 10px;
    font-weight: 900;
}
.TabSubTitulo.blue{
    color: #0098DC;
}
.TabSubTitulo.green{
    color: #669935;
}
.TabDetalleItemContent strong{
    color: #0098DC;
}
.TabDetalleItemContent li{
    list-style: none;
}
.TabDetalleItemContent li::before{
    content: "\2022";
    color: #0098DC;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}
.TabDetalleContent.nav-tabs .nav-link{
    background: #0098DC;
    margin: 3px 5px;
    color: white;
    border-radius: 5px;
    width: 200px;
    padding: 10px 2px;
}
.TabDetalleContent.nav-tabs .nav-link.active{
    background: #8BCBEB;
    color: #1A5C7D;
}

.TabDetalleContent.nav-tabs .nav-link:hover{
    background: #8BCBEB;
    color: #1A5C7D;
}

@media screen and (max-width: 600px) { 
    .TabDetalleContent.nav-tabs .nav-link{
        background: #0098DC;
        margin: 3px 2px;
        color: white;
        border-radius: 5px;
        width: 120px;
        padding: 10px 2px;
        font-size: 0.6rem;
    }
    .TabDetalleContent.nav.nav-tabs{
        justify-content: center;
        margin-top: 15px;
    }
}

.TabDetalleTitle{
    color: #1B5575;
    text-align: center;
    margin: 20px 0;
}