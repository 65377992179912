.HomeContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
    grid-auto-rows: minmax(200px, auto);
}
.HomeItem.center{
    grid-column: 1 / 3;
    grid-row: 1;
}
.HomeItem.left {
    grid-column: 1 / 1;
    grid-row: 2;
}
.HomeItem.right {
    grid-column: 2 / 2;
    grid-row: 2;
}
.HomeItem.center h1{
    font-size: 4rem;
}
.HomeItem.center h2{
    font-size: 2.5rem;
    letter-spacing: 1.2rem;
    font-weight: 300;
}
.HomeContainer .HomeItem {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin: 0px auto;
    height: auto;
    color: white;
}

.HomeContainer .HomeItem h4 {
    margin: 30px auto;
}

.HomeVideoBack{
    position: fixed; 
    left: 0;
    bottom: 0;
    z-index: -1;
    margin: 0 auto;
    min-width: 100%;
    min-height: 100%;
}
.bg-video::before{
    content: "";
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    position: fixed;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.525);
}

@media screen and (max-width: 500px) {
    .HomeContainer {
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: minmax(100px, auto);
    }
    .HomeItem.center{
        grid-column: 1 / 1;
        grid-row: 1;
    }
    .HomeItem.center h1{
        font-size: 3.4rem;
    }
    .HomeItem.center h2{
        font-size: 1.5rem;
        letter-spacing: 1.2rem;
        font-weight: 300;
    }
}