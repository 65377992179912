.main-SubTitulo{
    text-align: center;
    margin-bottom: 30px;
}
.main-SubTitulo.white h2 {
    color: #fff;
    font-size: 3rem;
}
.main-SubTitulo.white h2::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    right: 0;
    margin: 0 auto;
    width: 25%;
    height: 4px;
    background-color: #fff;
}
.main-SubTitulo h2 {
    color: #19577D;
    font-size: 3rem;
}
.main-SubTitulo h2::before{
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    right: 0;
    margin: 0 auto;
    width: 25%;
    height: 4px;
    background-color: #19577D;
}
@media screen and (max-width: 600px) {
    .main-SubTitulo h2 {
        color: #19577D;
        font-size: 1.7rem;
    }
}