.ExtrasComponentContainer{
    margin: 30px 0;
    padding: 0 20px;
}
.ExtrasComponentContainer{
    text-align: left;
    position: relative;
}
.ExtrasComponentContainer .ExtraComponentPrice::before{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin: 0 auto;
    width: 40%;
    height: 2px;
    background-color: #6B9C39;
}
.ExtrasComponentContainer h5{
    color: #0094D6;
    margin-bottom: 0;
    text-align: center;
    position: relative;
    padding-top: 20px;
}
.ExtrasComponentContainer h5 span{
    color: #6B9C39;
    font-size: 1.6rem;
}

.ExtrasComponentContainer table {
    width: 100%;
    margin: 10px;
  }
  .ExtrasComponentContainer  table td:first-child,
.ExtrasComponentContainer  table th {
    background-color: #006688;
    color: white;
  }
.ExtrasComponentContainer  table td {
    background-color: #AADCED;
    color: #4D7EA0;
    border: 1px solid white;
    padding: 10px;
  }