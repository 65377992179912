.Contain-main{
    position: relative;
    margin: 20px auto 15px auto;
    padding: 20px 30px;
    width: 100%;
    max-width: 1090px;
    height: 100%;
    min-height: 75vh;
}
@media screen and (max-width: 600px) {
    .Contain-main{
        margin: 10px auto 15px auto;
        padding: 10px 10px;
        width: 100%;
        max-width: 1090px;
        height: 100%;
        min-height: 75vh;
    }
}